<template>
  <div v-if="isValidTemplate" ref="compRef" :class="['info-card', { 'vert-cond': vertically_condensed }]">
    <div
      :class="[
        size,
        templateClass,
        template === 'full' ? `${verticalClass} ${horizontalClass}` : '',
        { reverse: reverse, 'black-bg': blackBg, 'grey-bg': greyBg },
        { 'splash-banner': isSplashPage },
        { 'padded-image': padded_image },
      ]"
      :style="isFull ? undefined : backgroundColorStyle"
    >
      <div :class="['content-block', { 'has-content-image': contentBgPublicId }]">
        <CloudinaryImage
          v-if="contentBgPublicId"
          :public-id="contentBgPublicId"
          :options="contentBgOptions"
          :alt="title || ''"
          class="image"
        />
        <Separator v-if="isFull" :level="0" />
        <slot v-else-if="isIcon" name="icon">
          <FireLine class="content-icon" />
        </slot>
        <CloudinaryImage
          v-if="inset_logo?.[0]?.public_id"
          :public-id="inset_logo[0].public_id"
          :options="insetImageSizes"
          class="inset-logo"
        />
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="content" class="content" :class="{ 'txt-white': blackBg || redBg }" v-html="content" />
        <!-- eslint-enable vue/no-v-html -->
        <div v-if="mappedButtons && mappedButtons.length" class="button-container flex-inline between">
          <CustomButton
            v-for="(button, index) in mappedButtons"
            v-show="!button?.shopify_buy_button?.enabled"
            :ref="setButtonEl"
            v-bind="button"
            :key="button.button_text + index"
            :to="button.to"
            :href="button.href"
            class="cta"
            :style-name="button?.type"
            :style-color="button?.style_color"
            @click-button="button?.isHashLink ? scrollToElementById(button.href as string, $event) : {}"
          >
            {{ button.button_text }}
          </CustomButton>
          <template v-if="buttonEls.length && shopifyBuyBtnContainersId !== '-'">
            <div
              v-for="(button, index) in filteredShopifyButtons"
              :id="'sbc' + button.button_text + shopifyBuyBtnContainersId + index"
              :key="'sbc' + button.button_text + shopifyBuyBtnContainersId + index"
              class="sbbtn"
            ></div>
            <ShopifyBuyButton
              v-for="(button, index) in filteredShopifyButtons"
              :key="'sb' + button.button_text + shopifyBuyBtnContainersId + index"
              :container-id="'sbc' + button.button_text + shopifyBuyBtnContainersId + index"
              :shopify-buy-btn="button.shopify_buy_button"
              :btn-class="buttonEls?.[index]?.computedClass.join(' ').replace(/\s+/g, ' ').trim()"
              :btn-text="button.button_text"
            />
          </template>
        </div>
      </div>
      <div v-if="isSplit && bgPublicId" class="image-block">
        <CloudinaryImage :public-id="bgPublicId" :options="bgOptions" class="image" :alt="title || ''" />
      </div>
    </div>
  </div>
  <div v-else-if="displayError" class="error">
    Invalid Template Combination - Template: {{ template }} Size: {{ size }}
    {{ !!bgPublicId ? 'Image present' : 'no image' }}
  </div>
</template>

<script setup lang="ts">
import { InfoCardSize, InfoCardTemplate, AssetCloudinary, ContentLayoutCS, ButtonCS } from '../types/contentstack';
import { generateIdNoDate } from '~/util/generateId';
import { mapMenuItem } from '~/util/contentstack/csHelpers';
import { scrollToElementById } from '~/util/eventHandler';
import { ImageOptions, useCloudinary } from '~/composables/cloudinary';
import { useDeviceStore } from '~/store/device';

const route = useRoute();
const cloudinary = useCloudinary();
const device = useDeviceStore();

const props = defineProps({
  /* eslint-disable vue/prop-name-casing */
  background_color: {
    type: Object as () => {
      color: string;
    },
    required: false,
    default: () => ({ color: '' }),
  },
  content_layout: {
    type: Object as () => ContentLayoutCS,
    required: true,
  },
  vertically_condensed: {
    type: Boolean,
    required: false,
    default: false,
  },
  padded_image: {
    type: Boolean,
    required: false,
    default: false,
  },
  inset_logo: {
    type: [Array, Object],
    required: false,
    default: () => [],
  },
  content_background_image: {
    type: Array as () => AssetCloudinary[],
    required: true,
    default: () => [],
  },
  /* eslint-enable vue/prop-name-casing */
  template: {
    type: String as () => InfoCardTemplate,
    required: true,
  },
  reverse: {
    type: Boolean,
    required: false,
    default: false,
  },
  background: {
    type: Array as () => AssetCloudinary[],
    required: true,
  },
  size: {
    type: String as () => InfoCardSize,
    default: 'large',
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  buttons: {
    type: Array as () => ButtonCS[],
    required: false,
    default: null,
  },
  content: {
    type: String,
    required: true,
    default: '',
  },
  displayError: {
    type: Boolean,
    default: false,
  },
  quality: {
    type: Number,
    default: undefined,
  },
});

const compRef = ref(null);
const buttonEls = reactive([]);
let buttonsSkipped = 0;
function setButtonEl(el) {
  if (props.buttons?.[buttonEls.length + buttonsSkipped]?.shopify_buy_button?.enabled) {
    buttonEls.push(el);
  } else {
    buttonsSkipped++;
  }
}

const isSplashPage = computed<boolean>(() => {
  return route?.fullPath?.includes('/sp/');
});

const templateClass = computed<string>(() => {
  if (isIcon.value) {
    // these share split template with gray background, image-block is protected with v-if
    return 'split-container';
  }
  return props.template + '-container';
});
const isValidTemplate = computed<boolean>(() => {
  if ((isFull.value && !bgPublicId.value) || (isIcon.value && props.size !== 'small')) {
    return false;
  }
  return true;
});
const isSplit = computed<boolean>(() => props.template === 'split');
const isFull = computed<boolean>(() => props.template === 'full');
const isIcon = computed<boolean>(() => props.template === 'icon');
const horizontalAlign = computed<string>(() => props.content_layout?.horizontal_alignment);
const verticalAlign = computed<string>(() => props.content_layout?.vertical_alignment);
const bgPublicId = computed<string>(() => props.background?.[0]?.public_id);
const bgOptions = computed<ImageOptions | undefined>(() => {
  const bg = props.background;
  const width = props.vertically_condensed ? 653 : 681;
  const height = props.vertically_condensed ? 364 : 460;
  const widthMob = 375;
  const heightMob = 274;
  const widthTab = 377;
  const heightTab = 420;
  return bg?.length
    ? {
        width: device.isMobile ? widthMob : device.isDesktop ? width : widthTab,
        height: device.isMobile ? heightMob : device.isDesktop ? height : heightTab,
        quality: 'auto',
      }
    : undefined;
});
// const backgroundUrl = computed<ImageOptions | undefined>(() => {
//   const bg = props.background;
//   const width = props.vertically_condensed ? 575 : 1200;
//   const height = props.vertically_condensed ? 320 : 812;
//   const widthMob = 900;
//   const heightMob = 300;
//   return bg?.length
//     ? {
//         width: device.isMobile ? widthMob : width,
//         height: device.isMobile ? heightMob : height,
//       }
//     : undefined;
// });
const contentBgPublicId = computed<string>(() => props.content_background_image?.[0]?.public_id);
const contentBgOptions = computed<ImageOptions | undefined>(() => {
  const bg = props?.content_background_image;
  const width = props.vertically_condensed ? 575 : 710;
  const height = props.vertically_condensed ? 320 : 480;
  const widthMob = 900;
  const heightMob = 300;
  return bg?.length
    ? {
        width: device.isMobile ? widthMob : width,
        height: device.isMobile ? heightMob : height,
        quality: 'auto',
      }
    : undefined;
});
const fullDeskBackgroundUrl = computed<string>(() => {
  const bg = props.background as AssetCloudinary[];
  return bg?.length ? getBgImage(cloudinary.generateImageUrl(bg?.[0]?.public_id, { width: 1361, height: 420 })) : '';
});
const tabBackgroundUrl = computed<string>(() => {
  const bg = props.background as AssetCloudinary[];
  return bg?.length ? getBgImage(cloudinary.generateImageUrl(bg[0]?.public_id, { width: 753, height: 420 })) : '';
});
// const mobBackgroundUrl = computed<string>(() => {
//   const bg = props.background as AssetCloudinary[];
//   return bg?.length ? getBgImage(cloudinary.generateImageUrl(bg?.[0]?.public_id, { width: 768, height: 450 })) : '';
// });

const insetImageSizes = computed<ImageOptions>(() => {
  return { width: device.isMobile ? 295 : device.isDesktop ? 400 : 216, quality: 'auto' };
});
const blackBg = computed<boolean>(
  () => props.background_color?.color === '#1a1a1a' || props.background_color?.color === '#000000'
);
const redBg = computed<boolean>(() => props.background_color?.color === '#CC092F');
const greyBg = computed<boolean>(() => props.background_color?.color === '#f5f2ef');
const backgroundColorStyle = computed<string | undefined>(() => {
  return props.background_color?.color ? `background-color: ${props.background_color.color}` : undefined;
});
const horizontalClass = computed<string>(() => 'horizontal-' + horizontalAlign.value);
const verticalClass = computed<string>(() => 'vertical-' + verticalAlign);
const mappedButtons = computed(() => {
  return props?.buttons?.map((button) => {
    return {
      ...button,
      ...mapMenuItem(button),
    };
  });
});
const filteredShopifyButtons = computed(() => {
  return props?.buttons?.filter((button) => {
    return button.shopify_buy_button?.enabled;
  });
});

const shopifyBuyBtnContainersId = computed<any>(() =>
  compRef.value && filteredShopifyButtons.value?.length ? generateIdNoDate('sbic') : '-'
);

function getBgImage(url: string) {
  return `linear-gradient(rgb(0 0 0 / 55%) 100%, rgb(0 0 0 / 55%) 0%), url('${url}')`;
}
</script>

<style scoped>
.split-container.splash-banner {
  background-color: var(--splash-theme-color);
}
.full-container {
  background-blend-mode: overlay;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .full-container {
    background-image: v-bind(tabBackgroundUrl);
  }
}
@media screen and (min-width: 1280px) {
  .full-container {
    background-image: v-bind(fullDeskBackgroundUrl);
  }
}
</style>

<style lang="scss" scoped>
$desk-side-padding: 80px;
$desk-med-side-padding: 64px;

.full-container,
.split-container {
  // border-radius: 4px;
  height: fit-content;
  min-height: #{local-functions.rem-calc(420px)};
  width: 100%; // default size is large

  .content-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    .h2 {
      font-size: #{local-functions.rem-calc(38px)};
      line-height: 120%;
      margin: 0 0 0.5rem;
    }

    .h4 {
      font-size: 1.5rem;
      line-height: 130%;
      margin: 0 0 0.5rem;
    }

    p {
      margin-bottom: 0;
    }

    svg.content-icon {
      height: #{local-functions.rem-calc(60px)};
      width: #{local-functions.rem-calc(60px)};
      margin-bottom: 1.5rem;
    }

    .btn {
      width: auto;
      @include local-mixins.tablet_and_mobile {
        width: 100%;
      }
    }

    .card-content {
      margin: 0;
    }
  }

  // .image {
  //   border-radius: 4px;
  // }

  @include local-mixins.desktop {
    &.small {
      width: 25%;
    }

    &.medium {
      width: 50%;
    }
  }
}

.split-container {
  display: flex;
  flex-direction: row;
  color: $color-neutral-cool-900;

  .content-block {
    padding: #{local-functions.rem-calc(76px $desk-side-padding)};
    justify-content: center;

    &.has-content-image {
      position: relative;
      .content,
      .btn {
        z-index: 1;
      }
      .image {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        left: 0;
      }
    }

    .btn {
      margin-top: 1.5rem;
    }
  }

  &.padded-image {
    @include local-mixins.desktop {
      min-height: #{local-functions.rem-calc(600px)};
      .image-block {
        padding: #{local-functions.rem-calc(76px $desk-side-padding 76px 0)};
        img {
          max-height: 28.5rem;
        }
      }
    }
  }

  .image-block {
    width: 50%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      // max-height: #{local-functions.rem-calc(500)};
      object-fit: cover;
    }
  }

  @include local-mixins.desktop {
    // desktop split large and medium only
    &.reverse {
      &.large,
      &.medium {
        .content-block {
          order: 1;
        }

        .image-block {
          order: 0;
        }
      }

      &.large {
        .content-block {
          padding: #{local-functions.rem-calc(76px $desk-side-padding)};
        }
      }
    }

    &.medium {
      .content-block {
        padding: #{local-functions.rem-calc(76px $desk-med-side-padding)};
        width: 50%;
      }
    }

    &.small {
      height: auto;
      flex-direction: column;

      .image-block {
        order: 0;
        width: 100%;
      }

      .content-block {
        order: 1;
        padding: #{local-functions.rem-calc(40px)};
        width: calc(100% - 5rem);
      }
    }
  }
}

.full-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  padding: #{local-functions.rem-calc(76px $desk-side-padding)};
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .separator {
    width: #{local-functions.rem-calc(88px)};
    margin-bottom: 2rem;
  }

  &.horizontal-left {
    justify-content: left;
  }

  &.horizontal-center {
    justify-content: center;

    .content-block {
      align-items: center;
    }
  }

  &.horizontal-right {
    justify-content: right;
  }

  &.vertical-top {
    align-items: flex-start;
  }

  &.vertical-center {
    align-items: center;
  }

  &.vertical-bottom {
    align-items: flex-end;
  }

  .content-block {
    height: 100%;
    padding: 0;
    text-transform: capitalize;
    text-align: left;
    color: $color-neutral-white;

    .btn {
      margin-top: 1.5rem;
    }
  }

  @include local-mixins.desktop {
    &.horizontal-left,
    &.horizontal-right {
      .content-block {
        align-items: flex-start;
      }

      .separator {
        margin-left: 0;
      }
    }

    &.small {
      padding: #{local-functions.rem-calc(40px 48px)};

      .content-block {
        width: 100%;
      }
    }
  }
}

@include local-mixins.mobile {
  .split-container {
    flex-direction: column;

    .image-block {
      order: 1;

      img {
        height: #{local-functions.rem-calc(274)};
      }
    }
    .content-block {
      padding: #{local-functions.rem-calc(40px)};
    }
  }

  .full-container {
    padding: #{local-functions.rem-calc(40px)};

    .content-block {
      padding: 0;
    }
  }

  .split-container,
  .full-container {
    height: auto;
    min-height: #{local-functions.rem-calc(420px)};

    .content-block,
    .image-block {
      width: 100%;
    }

    .content-block {
      order: 2;
      /* stylelint-disable-next-line value-no-vendor-prefix */
      max-width: -webkit-fill-available;

      .h2 {
        @include local-mixins.h3;
      }

      .h4 {
        font-size: #{local-functions.rem-calc(22px)};
        margin-bottom: 0.5rem;
      }
    }
  }

  .btn {
    width: 100%;
  }
}

.info-card.vert-cond {
  .split-container {
    min-height: #{local-functions.rem-calc(200)};

    @include local-mixins.desktop {
      min-height: #{local-functions.rem-calc(315)};
    }

    .image-block {
      @include local-mixins.desktop {
        width: 48%;

        img {
          // max-height: #{local-functions.rem-calc(315)};
        }
      }

      @include local-mixins.tablet {
        img {
          max-height: #{local-functions.rem-calc(350)};
        }
      }

      @include local-mixins.mobile {
        img {
          max-height: #{local-functions.rem-calc(200)};
        }
      }
    }

    .content-block {
      @include local-mixins.desktop {
        width: 52%;
        padding: 0;
        padding-left: 2rem;
      }

      @include local-mixins.tablet_and_mobile {
        padding: 1rem;
      }
    }

    &:not(.reverse) {
      @include local-mixins.desktop {
        &:not(.black-bg) {
          .content-block {
            padding-left: 0;
          }
        }
        .content-block {
          padding-right: 2rem;
        }
      }
    }
  }
}

.button-container {
  &.flex-inline {
    @include local-mixins.tablet_and_mobile {
      flex-wrap: wrap;
    }
  }
  @include local-mixins.desktop {
    .btn + .btn {
      margin-left: 12px;
    }
  }
}
.inset-logo {
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 2rem;
}
.error {
  height: 100px;
  width: 200px;
  color: $color-warning-default;
  border: 2px solid $color-warning-default;
  text-align: center;
  display: flex;
  align-items: center;
}

.sbbtn {
  margin-top: 1.5rem;
}
</style>
